<template>
  <v-container class=" pa-0">
    <v-row class="ma-0">
      <v-col>
        <v-row>
          <v-col>
            <v-toolbar dense color="red" height="50" flat class="rounded-xl">
              <v-card-title primary-title class="white--text">
                <h4>
                  {{ modo }} Actividad
                  <v-icon color="red" class="mb-1">
                    mdi-pencil
                  </v-icon>
                </h4>
              </v-card-title>
              <v-spacer />

              <v-btn
                align="right"
                color="orange"
                dark
                small
                class="ma-1"
                @click="irCatalogo"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>

              <v-btn
                align="right"
                color="orange"
                dark
                small
                class="ma-1"
                @click="regresar"
              >
                <v-icon>mdi-reply</v-icon>
              </v-btn>

              <v-btn dark small color="blue" @click="saveInfo">
                Procesar
              </v-btn>
            </v-toolbar>
          </v-col>
        </v-row>

        <!-- FORMA -->

        <v-form ref="form" v-model="valid" class="ma-0 pa-0" lazy-validation>
          <!--CONTACTO-->
          <v-row class="ma-0 pa-0 mt-2">
            <v-col cols="12" md="5" sm="5" xm="10" class="ma-0">
              <v-text-field
                v-if="mostrarDatosOrden"
                v-model="titulo"
                color="orange"
                outlined
                dense
                label="Titulo"
              />

              <v-card class="rounded-lg" elevation="5">
                <v-row justify="center">
                  <h3 class="mt-2">
                    Contacto
                  </h3>
                </v-row>
                <!-- CONTACTOS -->
                <leecontacto />

                <v-text-field
                  v-model="empresa"
                  prepend-icon="mdi-briefcase"
                  hide-details
                  class="ma-4"
                  label="Empresa"
                />

                <v-text-field
                  v-model="email"
                  dense
                  prepend-icon="mdi-at"
                  hide-details
                  class="ma-4"
                  label="Email"
                />

                <v-text-field
                  v-model="telefono"
                  prepend-icon="mdi-phone"
                  hide-details
                  dense
                  class="ma-4"
                  label="Teléfono"
                />

                <v-text-field
                  v-model="celular"
                  prepend-icon="mdi-cellphone-iphone"
                  hide-details
                  dense
                  class="ma-4"
                  label="Celular"
                />

                <leecliente />
              </v-card>
            </v-col>

            <v-col cols="12" md="7" sm="7" class="ma-0 pa-0">
              <v-row>
                <!-- FECHA -->
                <v-col cols="12" md="6" sm="6" xs="10">
                  <v-menu
                    v-model="menu1"
                    :nudge-right="40"
                    min-width="290px"
                    class="ma-0 pa-0"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="fechaini"
                        hide-details
                        filled
                        background-color="white"
                        dense
                        rounded
                        label="Fecha"
                        prepend-inner-icon="mdi-calendar"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="fechaini" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(fechaini)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <!-- TIPO DE ACTIVIDAD -->
                <v-col cols="12" md="6" sm="6" xs="10">
                  <v-select
                    v-model="select"
                    dense
                    class="mt-2"
                    outlined
                    background-color="white"
                    color="red"
                    prepend-inner-icon="reorder"
                    :items="tipoact"
                    item-text="nomtipoact"
                    item-value="idtipoact"
                    label="Tipo de actividad"
                    persistent-hint
                    return-object
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-card>
                <v-row class="mx-1 mt-2">
                  <v-col cols="6">
                    <!-- HORA INICIAL -->
                    <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time"
                          outlined
                          label="Hora Inicio"
                          prepend-inner-icon="mdi-clock-time-four-outline"
                          readonly
                          hide-details
                          dense
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>

                      <v-time-picker
                        v-if="menu2"
                        v-model="time"
                        format="24hr"
                        full-width
                        dense
                        @click:minute="$refs.menu.save(time)"
                      />
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <!-- Hora final -->
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="timefin"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="timefin"
                          outlined
                          label="Hora Final"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          hide-details
                          dense
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>

                      <v-time-picker
                        v-if="menu3"
                        v-model="timefin"
                        format="24hr"
                        full-width
                        dense
                        @click:minute="$refs.menu3.save(timefin)"
                      />
                    </v-menu>
                  </v-col>

                  <v-col cols="12">
                    <!-- ACTIVIDAD OBS. -->
                    <v-textarea
                      v-model="obs"
                      background-color="blue-grey lighten-5"
                      color="red"
                      auto-grow
                      row-height="30"
                      rows="3"
                      label="Actividad Realizada"
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card>

              <!-- //CAUSA -->
              <v-textarea
                v-if="mostrarDatosOrden"
                v-model="causa"
                background-color="blue-grey lighten-5"
                color="orange"
                dense
                auto-grow
                row-height="30"
                rows="2"
                label="Causa del problema"
                outlined
                rounded
              />

              <!-- DESCRIPCIÖN DEL PROBLAMD E LA ORDEN. -->
              <v-textarea
                v-if="mostrarDatosOrden"
                v-model="problema"
                color="orange"
                auto-grow
                label="Descripción de la orden"
                rows="4"
                outlined
                dense
                hide-details
              />

              <!-- ORDEN ID -->
              <v-col cols="12" xm="12" sm="4" md="4" class="ma-0">
                <v-text-field
                  v-if="mostrarDatosOrden"
                  v-model="idorden"
                  color="orange"
                  outlined
                  dense
                  hide-details
                  label="Orden No."
                  prepend-inner-icon="mdi-book"
                />

                <p v-if="mostrarDatosOrden" class="mt-2">Tipo {{ modulo }}</p>
              </v-col>

              <!-- CAUSA. -->
              <v-col cols="12" xm="12" sm="8" md="8" class="ma-0">
                <!-- MOSTRAR FOTO -->

                <v-dialog
                  v-if="mostrarDatosOrden"
                  transition="dialog-bottom-transition"
                  max-height="1200"
                  max-width="900"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col cols="12">
                      <v-card elevation="10" v-bind="attrs" v-on="on">
                        <v-subheader inset ligt height="15">
                          Imagen del problema
                        </v-subheader>
                        <v-img :src="linkfoto" contain max-height="auto" />
                      </v-card>
                    </v-col>
                  </template>

                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar color="primary" dark dense>
                        Imagen del problema
                      </v-toolbar>

                      <v-card-text>
                        <!-- <div class="text-h2 pa-12">Hello world!</div> -->
                        <v-card>
                          <v-img
                            contain
                            :src="linkfoto"
                            max-height="1200"
                            max-width="1200"
                          />
                        </v-card>
                      </v-card-text>

                      <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false">
                          Cerrar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
            </v-col>
          </v-row>
        </v-form>

        <!-- RUTA  -->
        <v-row>
          <v-col cols="6">
            <v-breadcrumbs dense :items="nav" class="red--text" />
          </v-col>

          <v-col cols="4">
            <v-toolbar color="red darken-2" dense dark>
              actividades
            </v-toolbar>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- TOOLBAR ROJO -->
  </v-container>
</template>

<script>
import horaSait from '@/mixins/horasait'
import leecontacto from '@/components/leecontacto'
import leecliente from '@/components/leecliente'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    leecontacto,
    leecliente
  },
  mixins: [horaSait],

  data: () => ({
    select: { nomtipoact: '', idtipoact: 0 },
    tipoact: [],

    nav: [
      {
        text: 'Panel',
        disabled: false,
        href: 'panel',
        to: 'panel'
      },
      {
        text: 'Catalogo de actividades',
        disabled: false,
        href: 'catactividades',
        to: 'catactividades'
      },
      {
        text: 'Nueva actividad',
        disabled: true,
        href: 'breadcrumbs_link_2',
        to: 'panel'
      }
    ],

    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters'
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],

    //DATOS DE ORDENES
    idorden: 0,
    titulo: 'Titulo de la orden.',
    problema: 'Descripción del problema a resolver.',
    creada: '2021-12-20',
    tipo: '',

    //DATOS DE CONTACTO

    celular: '',
    sucursal: '',
    departamento: '',
    numcli: '',
    numcont: '',
    nomcont: '',
    idcontacto: '',

    actividad: 'Llame',

    actividades: ['LLamo', 'Llame', 'Junta', 'Demo'],
    cliente: null,

    clientes: ['Pemex', 'Oxxo', 'Nemak', 'FICOSA'],

    contacto: null,
    contactos: [],
    items: [],
    // checkbox: false,
    telefono: '',
    telefonoRules: [
      v => !!v || 'El telefono es requerido.',
      v => (v && v.length <= 10) || 'Máximo 10 caracteres'
    ],

    // FECHAS
    fecha: new Date().toISOString().substr(0, 10),
    fechaini: new Date().toISOString().substr(0, 10),
    menu1: false,
    menu3: false,

    //autocomplete
    value: '',

    //Hora
    time: null,
    timefin: null,

    inicio: '10:20:36',
    termino: '10:45:05',
    menu2: false,

    //Descricion textare
    obs: '',

    //EMPRESA
    empresa: '',
    empresaRules: [
      v => !!v || 'La empresa es requerida',
      v => (v && v.length <= 40) || 'Máximo 40 characters'
    ],

    //TIMER
    formattedTime: '00:00:00',
    ticker: undefined,
    currentTimer: 0,
    newhora: '',
    nModo: '',
    modo: '',

    //ORDEN
    causa: '',
    modulo: '',
    mostrarDatosOrden: false,

    horafin: ''
  }),

  computed: {
    ...mapGetters('contactos', ['getContacto', 'getNewContacto']),
    ...mapGetters('login', ['getdatosUsuario']),

    validaInfo() {
      this.getNewContacto
      let lvalida = this.validate()
      // console.log("lvalida", lvalida)
      // console.log("nomcont", this.nomcont)
      if (lvalida == false) {
        return
      }

      // console.log("validar tipoact",this.select.idtipoact )
      //ELIMINANDO BUSQUEDAS VACIA
      return (
        this.select.idtipoact !== 0 &&
        this.telefono !== '' &&
        this.obs !== '' &&
        this.nomcont !== ''
      )
    }
  },

  watch: {
    getContacto() {
      // this.limpiar()
      this.actualizarDatoscontactos()
    },

    getNewContacto() {
      this.actualizarNomcont()
    }
  },

  created() {
    this.cargartipoact()

    this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)

    // Inicia contador
    this.tick()
    this.newhora = this.horaSait()
    this.time = this.horaSait()

    this.timefin = this.horaSait()
    // this.requestSMSPermission()

    // this.idcita = ''
    console.log('router', this.$route.params)
    let ruta = this.$route.params.actividad
    // this.idcontacto = this.$route.params.contacto.idcontacto

    let rutaOrden = this.$route.params.addorden

    //MODO EDITAR
    if (ruta !== undefined) {
      this.nModo = 2
      this.readonly = true

      this.cargarInfo(this.$route.params.actividad)
      this.modo = 'Editar'
    } else {
      // console.log("nuevo")
      //VALORES INICIALES ALTA
      this.nModo = 1
      this.modo = 'Nueva'
    }

    // REGISTRAR SERVICIO A UNA ORDEN
    if (rutaOrden !== undefined) {
      this.nModo = 1
      this.modo = 'Nueva'
      //Carga la información de la orden.
      this.cargarInfoOrden(this.$route.params.addorden)
    }
  },

  methods: {
    ...mapActions('contactos', ['setContacto', 'setLimpiarContacto']),
    ...mapActions('snackbar', ['showSnack']),
    ...mapActions('controles', ['setDrawer2']),

    irCatalogo() {
      this.$router.push({ name: 'catactividades' })
    },

    regresar() {
      this.$router.go(-1)
      // if (this.nModo == 2) {
      //   this.$router.go(-1)
      //   console.log('ruta', this.$route.name)
      // }

      // if (this.nModo == 1 && this.mostrarDatosOrden == false) {
      //   //Con Vuex modo lateral
      //   this.setDrawer2(false)
      // }

      // if (this.nModo == 1 && this.mostrarDatosOrden == true) {
      //   //Con Vuex modo lateral
      //   this.setDrawer2(false)
      //   this.$router.go(-1)
      // }
    },

    cargartipoact() {
      // limpiar
      this.tipoact = []
      this.loading = true

      this.$http
        .get('v2/get.nomtipoact.activos')
        .then(response => {
          // console.log("response", response)
          if (response.data.error === null) {
            this.tipoact = response.data.result
            this.loading = false

            // this.select = { nomtipoact: response.data.result[0].nomtipoact, idtipoact: response.data.result[0].idtipoact }
          }
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    cargarInfo(actividad) {
      console.log('actividad a editar', actividad)

      this.tipo = actividad.tipo
      this.obs = actividad.obs
      this.idcontacto = actividad.idcontacto
      this.fecha = actividad.fecha
      this.actividad = actividad.actividad
      this.terminada = actividad.terminada
      this.idcrm = actividad.idcrm

      this.time = actividad.hora
      this.timefin = actividad.horafin

      // FORMAR SELEC TIPO ACT
      this.select = {
        nomtipoact: actividad.tipo,
        idtipoact: actividad.idtipoact
      }

      //       this.select.nomtipoact = this.tipo
      // this.select.idtipoact = this.idtipoact

      let contacto = {
        nomcont: actividad.nomcont,
        idcontacto: actividad.idcontacto,
        empresa: actividad.empresa,
        telefono: actividad.telefono,
        numcli: '',
        email: actividad.email,
        celular: actividad.celular,
        sucursal: '',
        departamento: ''
      }

      this.setContacto(contacto)
    },

    cargarInfoOrden(orden) {
      console.log('orden a editar', orden)
      this.mostrarDatosOrden = true

      // DATOS DE LA ORDEN
      this.idorden = orden.idorden
      this.problema = orden.problema
      this.idcontacto = orden.idcontacto
      this.modulo = orden.modulo
      this.titulo = orden.titulo

      this.linkfoto =
        'https://www.soportesait.com/fotossait/' +
        this.idorden.toString() +
        '.png'

      console.log('linkfoto', this.linkfoto)

      let contacto = {
        nomcont: orden.nomcont,
        idcontacto: orden.idcontacto,
        empresa: orden.empresa,
        telefono: orden.telefono,
        email: orden.email,
        celular: orden.celular,
        sucursal: '',
        departamento: '',
        numcli: ''
      }

      this.setContacto(contacto)
    },

    actualizarDatoscontactos() {
      this.celular = this.getContacto.celular
      this.empresa = this.getContacto.empresa
      this.idcontacto = this.getContacto.idcontacto

      this.nomcont = this.getContacto.nomcont

      this.email = this.getContacto.email
      this.numcli = this.getContacto.numcli

      this.numcont = this.getContacto.numcont
      this.sucursal = this.getContacto.sucursal
      this.telefono = this.getContacto.telefono

      this.departamento = this.getContacto.departamento
    },

    actualizarNomcont() {
      this.nomcont = this.getNewContacto.nomcont
    },

    // checkSMSPermission: function() {
    //   var success = function (hasPermission) {
    //       if (hasPermission) {
    //           sms.send(...);
    //       }
    //       else {
    //           // show a helpful message to explain why you need to require the permission to send a SMS
    //           // read http://developer.android.com/training/permissions/requesting.html#explain for more best practices
    //       }
    //   };
    //   var error = function (e) { alert('Something went wrong:' + e); };
    //   sms.hasPermission(success, error);
    // },

    requestSMSPermission: function() {
      console.log('permisos SMS')
      var success = function(hasPermission) {
        if (!hasPermission) {
          sms.requestPermission(
            function() {
              console.log('[OK] Permission accepted')
            },
            function(error) {
              console.info('[WARN] Permission not accepted')
              // Handle permission not accepted
            }
          )
        }
      }
      var error = function(e) {
        alert('Something went wrong:' + e)
      }
      sms.hasPermission(success, error)
    },

    sendSms() {
      let number = this.telefono.toString()
      let message = this.obs

      console.log('number=' + number + ', message= ' + message)

      //CONFIGURATION
      var options = {
        replaceLineBreaks: false,
        android: {
          // intent: 'INTENT'  // send SMS with the native android SMS messaging
          intent: '' // send SMS without opening any other app, require : android.permission.SEND_SMS and android.permission.READ_PHONE_STATE
        }
      }

      let enviado = function() {
        alert('Message sent enviadofully')
      }
      let error = function(e) {
        alert('Message Failed:' + e)
      }
      sms.send(number, message, options, enviado, error)
    },

    saveInfo() {
      // console.log("saveInfo telefono", this.telefono, this.validainfo)

      if (!this.validaInfo) {
        this.showSnack({
          text: 'Debes registrar el teléfono, descripción y contacto. ',
          color: 'orange',
          timeout: 2000
        })
        return
      }
      // // this.validaInfo()
      // console.log(this.telefono)
      // this.sendSms()

      let horafin = this.formattedTime

      //CONTADOR DE TIEMPO
      // this.fechafin = this.fechaini + ' ' + this.formattedTime
      // this.fechaini = this.fechaini + ' ' + this.newhora

      // TIME PICKERS
      this.fechafin = this.fechaini + ' ' + this.timefin
      this.fechaini = this.fechaini + ' ' + this.time

      // Determinar modo del contactol contacto.
      if (this.nomcont == '') {
        //content
        console.log('nomcont', this.nomcont)
      }

      // this.getNewContacto()
      this.nomcont = this.getNewContacto

      let payload = {
        contactos: {
          nomcont: this.nomcont,

          empresa: this.empresa,
          telefono: this.telefono,
          email: this.email,
          celular: this.celular,
          sucursal: this.sucursal,
          departamento: this.departamento,

          numcli: this.numcli,
          numcont: this.numcont
        },
        actividad: {
          tipo: this.select.nomtipoact,
          idtipoact: this.select.idtipoact,

          fecha: this.fechaini,
          fechafin: this.fechafin,
          idusuariosweb: this.idusuariosweb,

          terminada: '0',
          actividad: '1',

          obs: this.obs,
          seguim: '',

          idorden: this.idorden,
          idcita: 0,
          idcontacto: parseInt(this.idcontacto),

          //De la forma
          numuser: 'SAIT',
          numusuargen: 'SAIT'
        }
      }

      // SI ES DE UNA ORDEN
      if (this.mostrarDatosOrden) {
        payload.orden = {
          titulo: this.titulo,
          idorden: this.idorden,
          problema: this.problema,
          idcontacto: this.idcontacto,
          idusuariosweb: this.idusuariosweb,
          modulo: this.modulo,
          causa: this.causa
        }
      }

      //Payload para poder modificar el contacto.
      // idcontacto : parseInt(this.idcontacto),
      if (this.getContacto.idcontacto != '') {
        payload.contactos.idcontacto = this.idcontacto
      }

      console.log('actividad payload', payload)

      //MODO NUEVO
      if (this.nModo == 1) {
        //Dedinir db name
        this.$http
          .post('v2/insert.actividad', payload)
          .then(response => {
            console.log('response', response)

            this.showSnack({
              text: 'actividad Agregada correctamente',
              color: 'success',
              timeout: 2000
            })

            this.limpiarActividad()
            // this.drawer= false
            // setTimeout (() => this.$router.push({name:'catactividades'}),2000);

            //VUEX Mandar cerrar vista lateral.
            this.setDrawer2(false)
          })
          .catch(error => {
            console.log(error)
            console.log('response error', error.data.error)
            this.showSnack({
              text: 'Problema para agendar actividad.' + error.data.error,
              color: 'info',
              timeout: 2000
            })
          })
      } else {
        //cambiar estatus a 1. Creada.
        payload.actividad.idcrm = this.idcrm

        console.log('payload', payload)
        // EDICION DE UNA actividad.
        this.$http
          .put('v2/update.actividad', payload)
          .then(response => {
            this.showSnack({
              text: 'Actividad actualizada ',
              color: 'success',
              timeout: 2000
            })

            this.limpiarActividad()
            setTimeout(
              () => this.$router.push({ name: 'catactividades' }),
              2000
            )
          })
          .catch(error => {
            this.showSnack({
              text: 'Problema para actualizar actividad.',
              color: 'error',
              timeout: 2000
            })
            console.log(error)
          })
      }

      // this.limpiarActividad()
    },

    limpiarActividad() {
      this.fechaini = new Date().toISOString().substr(0, 10)
      this.telefono = ''
      this.newhora = this.horaSait()

      this.setLimpiarContacto()
    },

    tick() {
      this.ticker = setInterval(() => {
        this.currentTimer++
        // this.currentTimer= this.newhora
        this.formattedTime = this.formatTime(this.currentTimer)
      }, 1000)
    },

    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60 * 1000
      )
      return newDate
    },

    formatTime(seconds) {
      var horaweb = new Date()
      let measuredTime = this.convertUTCDateToLocalDate(horaweb)
      measuredTime.setSeconds(seconds)
      let MHSTine = measuredTime.toISOString().substr(11, 8)
      return MHSTine
    },

    validate() {
      return this.$refs.form.validate()
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    }
  }
}
</script>
